<template>
  <v-footer
    color="primary"
    class="footer__container d-flex flex-column justify-center align-center py-3 flex-md-row"
  >
    <v-row class="footer__container__row py-6">
      <v-col
        cols="12"
        md="3"
        class="d-flex flex-column align-center my-md-6 mb-0"
      >
        <img
          class="narin-logo mr-3"
          :src="narinLogo"
        >
        <div class="footer__logo__text d-flex flex-column mt-6">
          <span>บริษัท นรินทร์เภสัช จำกัด</span>
          <span>Narin Pharmacy CO.,LTD.</span>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="contact__container d-flex flex-column align-md-start align-center"
      >
        <span>Contact us</span>
        <div class="mt-3">
          <div
            class="contact__logo__text d-flex mt-3"
            @click="gtagTel"
          >
            <img
              class="contact__logo mr-3"
              :src="phoneNumberLogo"
            >
            <span>
              <a
                href="tel:0888868628"
                style="color:white"
              >088-886-8628</a></span>
          </div>
          <div
            class="contact__logo__text d-flex mt-3"
            @click="gtagLine"
          >
            <img
              class="contact__logo mr-3"
              :src="lineLogo"
            >
            <span>{{ getUserLineId() }}</span>
          </div>
          <div
            class="contact__logo__text d-flex mt-3"
            @click="gtagFacebook"
          >
            <img
              class="contact__logo mr-3"
              width="28px"
              height="28px"
              :src="facebookLogo"
            >
            <span>narinbhaesaj</span>
          </div>
          <div class="contact__address__container d-flex flex-column mt-3">
            <span>273, 35ก ถนน ยุติธรรม ในเมือง, เมือง, ชัยภูมิ 36000</span>
            <span>เปิดบริการ จ. - ส. 08:00 น. - 18:00 น.</span>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="d-flex flex-column align-md-start align-center"
      >
        <div>
          <span class="navigate__header">E-commerce</span>
        </div>
        <v-row
          class="full-width mt-3"
          no-gutters
        >
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-column align-md-start align-center"
          >
            <span
              class="sub__navigate__text"
              @click="$router.push({ 'name' : 'Why2Buy' })"
            >ทำไมต้องซื้อกับเรา</span>
            <span
              class="sub__navigate__text mt-3"
              @click="$router.push({ 'name' : 'ReturnPolicy' })"
            >การเปลี่ยนคืนสินค้า</span>
            <span
              class="sub__navigate__text mt-3"
              @click="$router.push({ 'name' : 'How2Buy' })"
            >วิธีการสั่งซื้อ</span>
            <span
              class="sub__navigate__text mt-3"
              @click="$router.push({ 'name' : 'DeliveryInfo' })"
            >อัตราค่าจัดส่ง</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-column mt-3 mt-md-0 align-md-start align-center"
          >
            <!-- <span class="sub__navigate__text">ประกาศจาก Admin</span> -->
            <span
              class="sub__navigate__text"
              @click="$router.push({ 'name' : 'Feedback' })"
            >ติชม/ร้องเรียน</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';

import NarinLogo from '@/assets/images/logo/nrn-logo.png';
import LineLogo from '@/assets/images/logo/icons-8-line.svg';
import FacebookLogo from '@/assets/images/logo/f-logo-rgb-white-1024@3x.png';
import PhoneNumberLogo from '@/assets/images/logo/25377.svg';

export default {
  name: 'Footer',
  data: () => ({
    narinLogo: NarinLogo,
    lineLogo: LineLogo,
    facebookLogo: FacebookLogo,
    phoneNumberLogo: PhoneNumberLogo,
  }),
  computed: {
    ...mapGetters('user', ['getUserInfo']),
  },
  methods: {
    getUserLineId() {
      return !this.getUserInfo || this.getUserInfo.level == 0 ? '@narinch' : '@narinonline'
    },
    gtagTel() {
      this.$gtag.event('conversion', {'send_to': 'AW-966302140/aq49COrKvIQDELyz4swD'});
    },
    gtagLine() {
      this.$gtag.event('conversion', {'send_to': 'AW-966302140/gcCNCNfKxYQDELyz4swD'});
      window.location.href = 'https://lin.ee/59vJm71';
    },
    gtagFacebook() {
      this.$gtag.event('conversion', {'send_to': 'AW-966302140/RcAvCMfqxYQDELyz4swD'});
      window.location.href = 'https://www.facebook.com/narinbhaesaj';
    }
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}

.footer__container__row {
  max-width: 95vw;
}

.narin-logo {
  width: 120px;
  height: 77px;
}

.footer__logo__text > span {
  font-family: Kanit;
  font-size: 20px;
  line-height: 1.5;
  color: #ffffff;
}

.contact__container > span {
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #ffffff;
}

.contact__logo__text {
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
}

.contact__logo {
  width: 28px;
}

.contact__address__container {
  font-family: Sarabun;
  font-size: 14px;
  line-height: 1.57;
  color: #ffffff;
}

.navigate__header {
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #ffffff;
}

.sub__navigate__text {
  cursor: pointer;
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
}
</style>
