<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="cart__icon--mobile"
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="green"
            :content="countProduct > 9 ? '9+' : countProduct"
            offset-y="15"
            offset-x="5"
            v-if="countProduct > 0"
          >
            <v-icon
              color="white"
            >
              mdi-basket
            </v-icon>
          </v-badge>
          <v-icon
            color="white"
            v-else
          >
            mdi-basket
          </v-icon>
        </div>
      </template>
      <v-container class="cart__container__container--mobile d-flex flex-column justify-space-between pt-4">
        <div>
          <div class="d-flex justify-space-between">
            <span class="cart__header px-6">ตะกร้าสินค้า</span>
            <v-icon
              class="close__cart__btn px-6"
              @click="dialog = !dialog"
            >
              mdi-close
            </v-icon>
          </div>
          <v-checkbox
            label="เลือกทั้งหมด"
            class="cart__checkbox mt-2 px-6"
            input-value="true"
            disabled
          />
          <div class="cart__product__container--mobile mt-n4">
            <div
              v-for="(item, n) in cartNormalItems"
              :key="`cart-${n}`"
            >
              <div class="cart__product d-flex align-center px-6 pt-3 pb-2">
                <v-checkbox
                  input-value="true"
                  disabled
                />
                <div class="cart__product__image">
                  <v-img
                    :src="item.image"
                    width="80"
                    height="80"
                  />
                </div>
                <div class="cart__product__description d-flex flex-column ml-4 flex-grow-1">
                  <span class="cart__product__description__text">{{ item.name }}</span>
                  <span class="cart__product__description__generic">{{ item.genericName }}</span>
                </div>
                <div class="align-self-start">
                  <v-btn
                    icon
                    text
                    :color="isFavorites(item.id) ? 'pink' : 'grey'"
                    @click="addToWishList(item, isFavorites(item.id))"
                  >
                    <v-icon>{{ isFavorites(item.id) ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="cart__product__price d-flex align-center px-6 pb-3">
                <div class="product__detail__input__number">
                  <vue-number-input
                    :value="item.qty"
                    controls
                    :min="1"
                    :max="item.stock ? item.stock: 999999999"
                    center
                    @change="qty => onQtyUpdate(qty, item)"
                    @input="e => onKeyPress(e, item)"
                  />
                </div>
                <span class="ml-3">{{ item.unit }}</span>
                <v-spacer />
                <div class="cart__product__price d-flex flex-column align-center ml-4">
                  <span
                    v-if="isDiscountPromotionValid(item)"
                    class="cart__product__price__discount"
                  >
                    {{ getItemTotalPriceWithoutDiscount(item) }}
                  </span>
                  <span class="cart__product__price__text">{{ getItemTotalPrice(item) }}</span>
                </div>
                <div class="align-self-start">
                  <v-btn
                    icon
                    text
                    @click="onRemoveClick(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-divider />
            </div>
            <div
              v-for="(item, n) in cartCapsuleItems"
              :key="`cart-capsule-${n}`"
            >
              <div class="cart__product d-flex align-center px-6 pt-3 pb-2">
                <v-checkbox
                  input-value="true"
                  disabled
                />
                <div class="cart__product__image">
                  <v-img
                    :src="item.image"
                    width="80"
                    height="80"
                  />
                </div>
                <div class="cart__product__description d-flex flex-column ml-4">
                  <span class="cart__product__description__text">{{ item.name }}</span>
                  <span class="cart__product__description__generic">แลก {{ item.capsulePoint }} Capsules</span>
                  <span class="cart__product__description__generic">x{{ item.qty }} {{ item.unit }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="cart__summary__container">
            <div class="d-flex justify-space-between">
              <span class="cart__total__text">รวมทั้งสิ้น</span>
              <span class="cart__total__price">{{ getTotalPrice }}</span>
            </div>
            <div class="d-flex justify-space-between">
              <span class="cart__capsule__text">ได้รับ Capsule ทั้งหมด</span>
              <span class="cart__capsule__total">{{ getTotalCapsule }}</span>
            </div>
          </div>
          <div class="py-6 px-13">
            <v-btn
              color="secondary"
              depressed
              class="cart__submit full-width py-3 px-16"
              @click="onSubmit"
            >
              สั่งซื้อสินค้า
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { CHANGE_SNACKBAR_MESSAGE, SHOW_SNACKBAR, CHANGE_SNACKBAR_COLOR, ADD_WISHLIST_PRODUCT, REMOVE_WISHLIST_PRODUCT, UPDATE_FAVORITES } from '@/store/_actionTypes';
import { formatNumber } from "@/helpers/number";
import {
  isDiscountPromotionValid,
  applyDiscountPromotion,
  getPriceRate
} from "@/helpers/promotion";

export default {
  name: 'CartMobileMode',
  props: {
    cartNormalItems: {
      type: Array,
      default: () => [],
    },
    cartCapsuleItems: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    isAllItemSelected: true,
    isWishlist: false,
  }),
  created() {
    this.isAllItemSelected = this.cartNormalItems.every(item => item.selected);
  },
  computed: {
    ...mapGetters('user', [ 'isLoggedIn' ]),
    ...mapGetters('cart', [ 'getTotalPrice', 'getTotalPriceWithoutDiscount', 'getTotalCapsule', 'countProduct' ]),
    ...mapState('user', [ 'accessToken', 'favorites' ]),
  },
  methods: {
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR ]),
    ...mapActions('user', [ UPDATE_FAVORITES ]),
    ...mapActions('wishlist', [ ADD_WISHLIST_PRODUCT, REMOVE_WISHLIST_PRODUCT ]),
    isDiscountPromotionValid,
    isFavorites(productId) {
      return this.favorites?.includes(productId);
    },
    addToWishList(product, isWishlist) {
      if (!this.isLoggedIn) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_MESSAGE]('กรุณาเข้าสู่ระบบก่อนใช้งาน');
        this[CHANGE_SNACKBAR_COLOR]('red');
      } else {
        if (isWishlist) {
          this[REMOVE_WISHLIST_PRODUCT]({ product: product, accessToken: this.accessToken });
          this[UPDATE_FAVORITES](this.favorites.filter(item => item !== product.id));
        } else {
          let favorites = [...this.favorites, product.id];
          this[ADD_WISHLIST_PRODUCT]({ product: product, accessToken: this.accessToken });
          this[UPDATE_FAVORITES](favorites);
        }
      }
    },
    getItemTotalPriceWithoutDiscount: (item) => {
      return `฿${formatNumber(item.qty * getPriceRate(item))}`;
    },
    getItemTotalPrice: (item) => {
      return `฿${formatNumber(applyDiscountPromotion(item, getPriceRate(item)) * item.qty)}`;
    },
    onQtyUpdate(qty, product) {
      if (qty && qty !== product.qty) {
        this.$emit('updateQty', qty, product);
      }
    },
    onKeyPress(e, product) {
      this.$emit('updateQty', parseInt(e.target.value), product);
    },
    onRemoveClick(product) {
      this.$emit('removeItem', product);
    },
    selectItems() {
      this.$emit('updateSelected', this.isAllItemSelected, this.cartNormalItems.map(item => item.id));
    },
    selectItem(selected, productId) {
      this.$emit('updateSelected', selected, [productId]);
      this.isAllItemSelected = this.cartNormalItems.every(item => item.selected);
    },
    onSubmit() {
      if (this.countProduct === 0) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_MESSAGE]('กรุณาเพิ่มรายการสินค้าเข้าตะกร้า');
        this[CHANGE_SNACKBAR_COLOR]('red');
      } else if (!this.isLoggedIn) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_MESSAGE]('กรุณาเข้าสู่ระบบก่อนใช้งาน');
        this[CHANGE_SNACKBAR_COLOR]('red');
      } else {
        this.dialog = !this.dialog;
        this.$router.push({ name: 'Quotation' });
      }
    }
  }
};
</script>

<style lang="scss">

</style>
