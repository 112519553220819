import Vue from 'vue';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import ThailandAutoComplete from 'vue-thailand-address-autocomplete';
import VueGtag from "vue-gtag";

import App from './App.vue';
import router from './router';
import store from '../../store';
import vuetify from '../../plugins/vuetify';
import googlemap from '../../plugins/googlemap';

Vue.config.productionTip = false;
Vue.component('vue-number-input', VueNumberInput);
Vue.component('ThailandAutoComplete', ThailandAutoComplete);

Vue.use(VueGtag, {
  config: {
    id: "UA-208503103-1",
    params: {
      send_page_view: true
    }
  },
  includes: [
    { id: 'AW-966302140' },
  ]
}, router);

new Vue({
  router,
  store,
  vuetify,
  googlemap,
  render: (h) => h(App),
}).$mount('#app');
