<template>
  <div>
    <CartMobileMode
      v-if="isMobileScreenSize"
      :cart-normal-items="getNormalProduct"
      :cart-capsule-items="getCapsuleProduct"
      @updateQty="updateQty"
      @updateSelected="updateSelected"
      @removeItem="removeItem"
    />
    <CartDesktopMode
      v-else
      :cart-normal-items="getNormalProduct"
      :cart-capsule-items="getCapsuleProduct"
      @updateQty="updateQty"
      @updateSelected="updateSelected"
      @removeItem="removeItem"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CartDesktopMode from './components/CartDesktopMode.vue';
import CartMobileMode from './components/CartMobileMode.vue';

import { INIT_CART, UPDATE_QTY, UPDATE_SELECTED, REMOVE_ITEM } from '@/store/_actionTypes';

export default {
  name: 'Cart',
  components: {
    CartDesktopMode,
    CartMobileMode,
  },
  created() {
    this[INIT_CART]();
  },
  computed: {
    ...mapState('app', [ 'isMobileScreenSize' ]),
    ...mapState('cart', [ 'cartItems' ]),
    ...mapGetters('cart', [ 'getNormalProduct', 'getCapsuleProduct' ]),
  },
  methods: {
    ...mapActions('cart', [ INIT_CART, UPDATE_QTY, UPDATE_SELECTED, REMOVE_ITEM ]),
    updateQty(qty, product) {
      this[UPDATE_QTY]({ qty, product });
    },
    updateSelected(selected, productIds) {
      this[UPDATE_SELECTED]({ selected, productIds });
    },
    removeItem(product) {
      this[REMOVE_ITEM](product);
    },
  }
};
</script>

<style lang="scss">

.v-badge__badge {
    width: 14px;
    min-width: 14px !important;
    height: 14px !important;
    padding: 2px 2px !important;
    font-size: 10px !important;
}

.full-width {
    width: 100%;
}

.cart__header {
    font-family: Kanit;
    font-size: 20px;
}

.cart__icon {
    position:absolute;
    top: 0;
    left: -72px;
    width: 72px;
    padding: 8px 13px 10px 27px;
    border-radius: 24px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #751edf;

    &--mobile {
        z-index: 2;
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 72px;
        padding: 22px 0px 22px 22px;
        border-radius: 44px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        background-color: #751edf;
    }
}

.cart__container__container {
    padding: unset !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

    &--mobile {
        height: 100%;
        padding: unset !important;
        background-color: white;
    }
}

.cart__submit {
  font-family: Kanit;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: unset !important;
}

.cart__total__text {
  font-family: Sarabun;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #242424;
}

.cart__total__price {
  font-family: Sarabun;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: #751edf;
}

.cart__capsule__text {
  font-family: Sarabun;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  color: #242424;
}

.cart__capsule__total {
  font-family: Sarabun;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  text-align: right;
  color: #751edf;
}

.cart__summary__container {
  padding: 13px 32px;
  background-color: rgba(149, 61, 255, 0.05);
}

.cart__product__image {
  border-radius: 4px;
  border: solid 1px #eeeeee;
}

.cart__product__container {
    overflow-y: scroll;
    max-height: 35vh;
    min-width: 400px;

    &--mobile {
        overflow-y: scroll;
        max-height: calc(100vh - 261px);
    }
}

.cart__product__description {
  width: 180px;
  height: 60px;
}

.cart__product__description__text {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cart__product__description__generic {
  word-break: break-word;
  font-size: 12px;
  color: #999999;
}

.cart__product__price {
  position: relative;
}

.cart__product__price__discount {
  position: absolute;
  top: -13px;
  text-decoration: line-through;
  font-size: 12px;
  color: #999999;
}

.cart__product__price__text {
  font-size: 16px;
  color: #751edf;
  font-weight: 600;
}

.v-input--selection-controls__input > .v-icon {
  font-size: 18px !important;
}

.v-input__slot > .v-label {
  font-size: 14px;
  color: #242424 !important;
}

.product__detail__input__number {
  max-width: 180px;
}

</style>
