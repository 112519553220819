<template>
  <v-app class="app">
    <AppBar v-if="showAppBar" />
    <LoginDialog />
    <Snackbar
      :title="snackbarMessage"
      :color="snackbarColor"
      timeout="2000"
    />

    <v-main class="main">
      <SearchBar v-if="showSearchBar" />
      <router-view />
    </v-main>

    <Cart v-if="showCart" />
    <Footer v-if="showFooter" />
    <FooterCopyright />
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { MOBILE_SCREEN_SIZE } from '@/store/_actionTypes';

import AppBar from '@/components/AppBar/AppBar.vue';
import LoginDialog from '@/components/LoginDialog/LoginDialog.vue';
import SearchBar from '@/components/SearchBar.vue';
import Cart from '@/components/Cart/Cart.vue';
import Footer from '@/components/Footer.vue';
import FooterCopyright from '@/components/FooterCopyright.vue';
import Snackbar from '@/components/Snackbar/Snackbar.vue';

export default {
  name: 'App',
  components: {
    AppBar,
    LoginDialog,
    SearchBar,
    Cart,
    Footer,
    FooterCopyright,
    Snackbar,
  },
  data: () => ({
    showSearchBar: false,
    showCart: false,
    showFooter: false,
    showAppBar: false,
  }),
  computed: {
    ...mapState('app', ['snackbarMessage', 'snackbarColor']),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.name === 'Home' 
        || this.$route.name === 'ContactUs'
        || this.$route.name === 'AboutUs'
        || this.$route.name === 'Register') {
          this.showSearchBar = false;
          this.showCart = false;
          this.showFooter = false;
        } else {
          this.showSearchBar = true;
          this.showCart = true;
          this.showFooter = true;
        }

        if (this.$route.name === 'Quotation') {
          this.showCart = false;
        }

        if (this.$route.name === 'Register') {
          this.showAppBar = false;
        } else {
          this.showAppBar = true;
        }
      }
    },
  },
  beforeDestroy: () => {
    if (typeof window === 'undefined') return;

    window.removeEventListener('resize', this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },
  methods: {
    ...mapActions('app', [MOBILE_SCREEN_SIZE]),
    onResize() {
      this[MOBILE_SCREEN_SIZE](window.innerWidth < 600);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Sarabun&family=Muli&display=swap');
@import '@/styles/fonts.scss';

@media (min-width: 1904px) {
  .container {
      max-width: 1185px !important;
  }
}

.app {
  font-family: Muli !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.57 !important;
  letter-spacing: normal !important;
  color: #ffffff;
}

.v-application a {
  text-decoration: unset !important;
}

.main {
  padding: 0 !important;
}
</style>
