<template>
  <v-container class="mt-n3">
    <v-row>
      <v-col
        cols="12"
        md="2"
        class="search-bar__logo d-flex align-center justify-center justify-md-start"
        @click="logoClick"
      >
        <img
          class="search-bar__narin-logo mr-3"
          :src="narinLogo"
        >
        <span class="narin-text">นรินทร์เภสัช</span>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="search-bar d-flex justify-center align-center py-0 py-md-3"
      >
        <v-text-field
          v-model="searchValue"
          solo
          rounded
          label="ค้นหา ชื่อสินค้า, ชื่อบริษัท, รหัสสินค้า, Barcode, Generic name"
          prepend-inner-icon="mdi-magnify"
          autocomplete="off"
          :disabled="this.$route.name === 'Shop' ? loading : false"
          type="search"
          @keyup="onKeyUp"
          @keydown.enter="onSubmit"
          @click="onClick"
          v-closable="{ exclude: [], handler: 'hidePopup' }"
        />
        <div
          class="suggestion-container"
          @mousedown.prevent.stop
        >
          <div
            v-for="(item, n) in items"
            :key="`suggestion-${n}`"
            class="text-suggestion"
            @click.stop="suggestionClick(item)"
            @touchstart.stop.prevent="suggestionClick(item)"
          >
            <span>
              {{ item }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center justify-space-between justify-md-start"
      >
        <div class="d-flex align-center">
          <img
            :src="require('@/assets/images/medicine/nrn-drugs-capsules.png')"
            class="capsule__img"
          >
          <span class="capsule__text ml-2">คุณมี {{ getCapsule }} Capsules Point</span>
        </div>
        <v-btn
          class="capsule__market__button ml-4"
          color="secondary"
          outlined
          depressed
          rounded
          @click="capsuleClick"
        >
          <v-icon class="mr-2">
            mdi-store
          </v-icon>
          <span class="text-capitalize">Capsule market</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { GET_PRODUCTS, UPDATE_PAGE, UPDATE_KEYWORD, GET_PRODUCTS_SUGGESTION } from '@/store/_actionTypes';
import { isCancel } from 'axios';
import debounce from '@/helpers/debounce'
import NarinLogo from '@/assets/images/logo/nrn-logo.png';
import Vue from 'vue'
// This variable will hold the reference to
// document's click handler
let handleOutsideClick

Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value

      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]
          // See if this excluded element
          // is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })

      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },

  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
});

export default {
  name: 'SearchBar',
  data: () => ({
    items: [],
    searchValue: '',
    narinLogo: NarinLogo,
    timeout: null,
  }),
  computed: {
    ...mapState('shop', [ 'categoryFilter', 'sort', 'minPrice', 'maxPrice', 'page', 'keyword', 'loading' ]),
    ...mapState('user', [ 'accessToken' ]),
    ...mapGetters('user', ['getCapsule']),
  },
  methods: {
    ...mapActions('shop', [ GET_PRODUCTS, UPDATE_PAGE, UPDATE_KEYWORD, GET_PRODUCTS_SUGGESTION ]),
    capsuleClick() {
      this.$router.push({ name: 'CapsuleMarket' });
    },
    logoClick() {
      this.$router.push({ name: 'Shop' });
    },
    onSubmit() {
      this[UPDATE_KEYWORD](this.searchValue);
      this.items = [];
      if (this.timeout) clearTimeout(this.timeout)
      if (this.$route.name === 'Shop') {
        this[UPDATE_PAGE](1);
        this[GET_PRODUCTS]({
          keyword: this.keyword,
          sort: this.sort.id,
          categoryFilter: this.categoryFilter,
          minPrice: this.minPrice,
          maxPrice: this.maxPrice,
          page: this.page,
          accessToken: this.accessToken,
        });
      } else {
        this.$router.push({ name: 'Shop' });
      }
    },
    onKeyUp(e) {
      if (e.code == 'Enter') return;
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        try {
          const products = await this[GET_PRODUCTS_SUGGESTION]({ keyword: e.target.value, accessToken: this.accessToken });
          this.items = products.map(item => item.name);
        } catch(error) {
          if (!isCancel(error)) {
            throw error;
          }
        }
      }, 500)
    },
    suggestionClick(item) {
      this.searchValue = item;
      this.items = [];
      this.onSubmit();
    },
    async onClick() {
      try {
        const products = await this[GET_PRODUCTS_SUGGESTION]({ keyword: this.searchValue, accessToken: this.accessToken });
        this.items = products.map(item => item.name);
      } catch(error) {
        if (!isCancel(error)) {
          throw error;
        }
      }
    },
    hidePopup() {
      this.items = [];
    },
  },
};
</script>

<style scoped>
.suggestion-container {
  position: absolute;
  top: 0;
  width: 90%;
  margin-top: 58px;
  height: unset !important;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 99;
}

.text-suggestion {
  padding: 15px 30px 15px 30px;
  cursor: pointer;
}

.text-suggestion:hover {
  background-color: #eee;
}

.text-suggestion:last-child:hover {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.search-bar__logo {
  cursor: pointer;
}

@media screen and (min-width: 960px) {
  .search-bar__narin-logo {
    width: 28%
  }
}

.narin-text {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #242424;
}

.search-bar {
  position: relative;
}

.search-bar > div {
  height: 50px;
}

.capsule__text {
  font-family: Kanit;
  font-size: 14px;
  line-height: 1.57;
  color: #300463;
}

.capsule__img {
  padding: 8px;
  border-radius: 50%;
  background-color: rgba(117, 30, 223, 0.1);
}

.capsule__market__button {
  font-family: Kanit;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: unset;
  color: #751edf;
  height: 40px !important;
}

.v-text-field >>> input {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #999999;
}

.v-text-field >>> label {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #999999 !important;
}

.v-text-field >>> .v-input__slot {
  box-shadow: unset !important;
  background-color: #fbfbfb !important;
}
</style>
