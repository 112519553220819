import Vue from 'vue';
import VueGoogleMap from 'vuejs-google-maps';

Vue.use(VueGoogleMap, {
  load: {
    apiKey: 'AIzaSyAgppEWiYBKo_ucwngt5NF7ifNjiguDZqs',
  },
});

export default VueGoogleMap;
